<template>
  <div>
    <!-- 上半部 -->
    <div id="StockTopArea" class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="StockBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="StockBatch">
            <li>
              <button class="dropdown-item" @click="showModal('purchaseOrder')">
                建立叫貨單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('postToLineNotify')"
              >
                至 Line Notify
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('toLinePush')">
                至 Line 待推播列表
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="StockAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="StockAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
          </ul>
        </div>
        <!-- 常用搜尋 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="FrequentlySearched"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            常用搜尋
          </button>
          <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
            <li>
              <button class="dropdown-item" @click="frequentlySearched('缺貨')">
                缺貨
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('需叫貨')"
              >
                需叫貨
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('有庫存的商品')"
              >
                有庫存的商品
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.merchandiseName"
          >
            商品名稱 {{ recordAdvancedSearchData.merchandiseName }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.supplier"
          >
            供應商: {{ recordAdvancedSearchData.supplier.name }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.arrivalStatus !== '不選擇'
            "
          >
            到貨狀態:
            {{ recordAdvancedSearchData.singleSelect.arrivalStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.singleSelect.hasStock !== '不選擇'"
          >
            庫存狀態:
            {{ recordAdvancedSearchData.singleSelect.hasStock }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            上架區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
          <span v-if="recordAdvancedSearchData.deadLineTimeRange.switch">
            收單區間:
            <span
              v-if="
                recordAdvancedSearchData.deadLineTimeRange.startTime &&
                recordAdvancedSearchData.deadLineTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.deadLineTimeRange.startTime }}~{{
                recordAdvancedSearchData.deadLineTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.deadLineTimeRange.startTime &&
                !recordAdvancedSearchData.deadLineTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.deadLineTimeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.deadLineTimeRange.startTime &&
                recordAdvancedSearchData.deadLineTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.deadLineTimeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <ProgressBar :progressData="progressData"></ProgressBar>
    <div class="tw-container">
      <div class="management-datable">
        <DataTable
          class="p-datatable-sm"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          :value="stockList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="D4Row"
          :rowsPerPageOptions="[20, 50, 100]"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-stock-local"
          sortField="id"
          :sortOrder="-1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <FieldFilter
                whitchOneTable="stock"
                :D4FieldFilter="fieldFilter"
              ></FieldFilter>
              <div class="w-100">
                <p>表格搜尋</p>
                <input
                  class="form-control me-2"
                  v-model="searchGlobal['global'].value"
                  placeholder="表格搜尋: 商品名稱，商品款式，供應商，成本，已結未寄，倉庫總數，已配，可配現貨，缺貨，已叫，已叫還缺，還缺(已付)"
                  style="max-width: 99%"
                />
              </div>
            </div>
          </template>
          <!-- 多選 -->
          <Column
            field=""
            selectionMode="multiple"
            style="min-width: 50px; max-width: 50px"
          ></Column>
          <!-- 圖示 -->
          <Column
            v-if="fieldFilter[1].selected"
            field=""
            header="圖示"
            style="min-width: 55px; max-width: 55px"
          >
            <template #body="{ data }">
              <template
                v-for="img in data.merchandisePictureLinks"
                :key="img.id"
              >
                <Image
                  class="table-img"
                  :imageHash="img.imageHash"
                  size="s"
                  v-if="img.front"
                  :alt="img.imageHash"
                ></Image>
              </template>
              <img
                class="table-img"
                v-if="data.merchandisePictureLinks.length === 0"
                src="@/assets/other-images/noImg.png"
                alt=""
              />
            </template>
          </Column>
          <!-- 商品 -->
          <Column
            v-if="fieldFilter[2].selected"
            field="merchandise.name"
            header="商品"
            style="min-width: 150px; max-width: 150px"
            sortable
          >
            <template #body="{ data }">
              <router-link
                :title="`商品備註: ${data.merchandise.note}`"
                :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${data.merchandiseId}`"
                class="fw-bolder text-break"
                :class="{ 'text-danger': data.merchandise.deleted }"
                target="_blank"
                >{{ data.merchandise.name }}</router-link
              >
              <div title="庫存編號">
                <img class="icon" src="@/assets/icon/bookmark.png" alt="" />
                <span>：{{ data.id }}</span>
              </div>
              <p class="text-break text-secondary">
                收單: {{ data.deadLineTime }}
              </p>
            </template>
          </Column>
          <!-- 款式 -->
          <Column
            v-if="fieldFilter[0].selected"
            field="merchandiseStyle"
            header="款式"
            sortable
            style="min-width: 150px; max-width: 150px"
          >
            <template #body="{ data }">
              <p class="text-break">{{ data.merchandiseStyle }}</p>
            </template>
          </Column>
          <!-- 操作 -->
          <Column
            v-if="fieldFilter[3].selected"
            field=""
            header="操作"
            style="min-width: 50px"
          >
            <template #body="{ data }">
              <div class="dropdown dropend pointer">
                <img
                  src="@/assets/icon/settings.png"
                  alt=""
                  id="Setting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 25px; height: 25px"
                />
                <ul class="dropdown-menu" aria-labelledby="Setting">
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/editStock?id=${data.id}`"
                      >編輯庫存</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/merchOrder?search=${
                        data.merchOrderIds.length === 0
                          ? 'null'
                          : data.merchOrderIds
                      }`"
                      >訂單管理</router-link
                    >
                  </li>
                </ul>
              </div>
            </template>
          </Column>
          <!-- 供應商 -->
          <Column
            v-if="fieldFilter[4].selected"
            field="supplierName"
            header="供應商"
            style="min-width: 100px"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.supplierName }}</p>
            </template>
          </Column>
          <!-- 成本 -->
          <Column
            v-if="fieldFilter[5].selected"
            field="cost"
            header="成本"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.cost }}
            </template>
          </Column>
          <!-- 已結未寄 -->
          <Column
            v-if="fieldFilter[6].selected"
            field="checkedNotShippedQuantity"
            header="已結未寄"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.checkedNotShippedQuantity }}
            </template>
          </Column>
          <!-- 倉庫總數 -->
          <Column
            v-if="fieldFilter[7].selected"
            field="quantity"
            header="倉庫總數"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.quantity }}
            </template>
          </Column>
          <!-- 已配 -->
          <Column
            v-if="fieldFilter[8].selected"
            field="allocatedQuantity"
            header="已配"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.allocatedQuantity }}
            </template>
          </Column>
          <!-- 可配現貨 -->
          <Column
            v-if="fieldFilter[9].selected"
            field="availableFromStockQuantity"
            header="可配現貨"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.availableFromStockQuantity }}
            </template>
          </Column>
          <!-- 缺貨總數 -->
          <Column
            v-if="fieldFilter[10].selected"
            field="outOfStockQuantity"
            header="缺貨"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.outOfStockQuantity }}
            </template>
          </Column>
          <!-- 已叫 -->
          <Column
            v-if="fieldFilter[11].selected"
            field="purchasedQuantity"
            header="已叫"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              {{ data.purchasedQuantity }}
            </template>
          </Column>
          <!-- 已叫還缺 -->
          <Column
            v-if="fieldFilter[12].selected"
            field="totalShortageQuantity"
            header="需叫貨"
            sortable
            style="min-width: 85px"
          >
            <template #body="{ data }">
              {{ data.totalShortageQuantity }}
            </template>
          </Column>
          <!-- 還缺(已付) -->
          <Column
            v-if="fieldFilter[13].selected"
            field="paidShortageQuantity"
            header="還缺(已付)"
            sortable
            style="min-width: 85px"
          >
            <template #body="{ data }">
              {{ data.paidShortageQuantity }}
            </template>
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 建立叫貨單 modal -->
  <div              
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="purchaseOrderModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">建立叫貨單
            <a href="https://youtu.be/9VNlQ_O6L1I?si=Wn2Zu41PAkW624Fp" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <!-- 左邊商品 -->
            <div class="col-lg mb-2 p-0">
              <div class="d-flex justify-content-between">
                <p class="tw-border-start fw-bolder mb-2">叫貨明細</p>
                <i class="bi bi-chat-right-dots me-4 text-danger pointer can-click"  title="訂單備註" @click="showModal('merchandiseOrderHasNote')" v-if="Object.keys(merchandiseOrderHasNoteDatas).length > 0">
                </i>
              </div>
              <div style="height: 500px; overflow-y: scroll">
                <div
                  :class="{ 'bg-light': index % 2 === 0 }"
                  class="tw-container border m-0 mb-2"
                  v-for="(item, index) in purchaseOrder.bodies"
                  :key="item.id"
                >
                  <!-- 商品資訊 -->
                  <div class="row">
                    <div class="col-3 p-0">
                      <template
                        v-for="img in item.merchandisePictureLinks"
                        :key="img.id"
                      >
                        <Image
                          class="table-img"
                          :imageHash="img.imageHash"
                          size="s"
                          v-if="img.front"
                          :alt="img.imageHash"
                        ></Image>
                      </template>
                      <img
                        class="table-img"
                        v-if="item.merchandisePictureLinks.length === 0"
                        src="@/assets/other-images/noImg.png"
                        alt=""
                      />
                    </div>
                    <div class="col-9 p-0">
                      <p>商品名稱：{{ item.merchandise.name }}</p>
                      <p>商品樣式：{{ item.merchandiseStyle }}</p>
                      <template
                        v-for="supplier in item.merchandiseSupplierLinks"
                        :key="supplier.id"
                      >
                        <p v-if="supplier.preset">
                          預設供應商：{{ supplier.name }}
                        </p>
                      </template>
                    </div>
                  </div>
                  <hr class="mt-2" />
                  <!-- 數量 / 成本 -->
                  <div class="row">
                    <div class="form-floating col-6 p-0">
                      <input
                        onwheel="this.blur()"
                        id="TotalShortageQty"
                        type="number"
                        min="0"
                        class="form-control d-inline-block"
                        placeholder="數量"
                        v-model="item.totalShortageQty"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                      <label for="TotalShortageQty"
                        >數量<span class="text-danger"
                          >(還缺：{{ item.totalShortageQuantity }})</span
                        ></label
                      >
                    </div>
                    <div class="form-floating col-6 p-0">
                      <input
                        onwheel="this.blur()"
                        id="Cost"
                        type="number"
                        min="0"
                        class="form-control d-inline-block"
                        placeholder="成本/單位"
                        v-model="item.cost"
                        @input="item.cost = $methods.numberToFixed(item.cost)"
                      />
                      <label for="Cost">成本/單位</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右邊叫貨單資訊 -->
            <div class="col-lg mb-2 p-0">
              <p class="tw-border-start fw-bolder mb-2">叫貨單資訊</p>
              <select
                class="form-control mb-2"
                name="stockSuppliers"
                v-if="!suppliers.loading"
                v-model="purchaseOrder.header.supplierId"
              >
                <option :value="null" disabled>不選擇供應商</option>
                <template v-for="supplier in suppliers.data" :key="supplier.id">
                  <option :value="supplier.id">{{ supplier.name }}</option>
                </template>
              </select>
              <AreaLoading v-else></AreaLoading>
              <div class="form-floating mb-2">
                <input
                  onwheel="this.blur()"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="叫貨數量"
                  :value="orderGoods"
                  disabled
                />
                <label for="">叫貨數量</label>
              </div>
              <div class="form-floating mb-2">
                <input
                  onwheel="this.blur()"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="總金額"
                  :value="total"
                  disabled
                />
                <label for="">總金額:</label>
              </div>
              <div class="form-floating mb-2">
                <input
                  onwheel="this.blur()"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="運費"
                  v-model="purchaseOrder.header.shippingCost"
                  @input="
                    purchaseOrder.header.shippingCost = $methods.numberToFixed(
                      purchaseOrder.header.shippingCost
                    )
                  "
                />
                <label for="">運費:</label>
              </div>
              <div class="form-floating mb-2">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  placeholder="金額調整"
                  v-model="purchaseOrder.header.adjustment"
                  @change="
                    purchaseOrder.header.adjustment = $methods.numberToFixed(
                      purchaseOrder.header.adjustment
                    )
                  "
                />
                <label for="">金額調整:</label>
              </div>
              <div class="form-floating mb-2">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  placeholder="應付金額"
                  :value="payable"
                  disabled
                />
                <label for="">應付金額:</label>
              </div>
              <div class="form-floating">
                <textarea
                  class="form-control"
                  placeholder="備註"
                  rows="3"
                  v-model="purchaseOrder.header.note"
                  id="floatingTextarea2"
                  style="height: 100px"
                  maxlength="300"
                ></textarea>
                <label for="floatingTextarea2">備註:(300字)</label>
              </div>
            </div>
          </div>
          <hr />
          <p
            class="alert alert-danger fw-bolder mb-3"
            v-if="errorData.length > 0"
          >
            請注意! 商品:
            <span v-for="data in errorData" :key="data.id">
              {{ data.merchandise.name }}&ensp;
            </span>
            成本為 0 !
          </p>
          <!-- 叫貨結果 -->
          <p class="alert alert-secondary fw-bolder">
            <p class="text-center">確定要訂購以下商品嗎?</p>
            <hr>
            <table class="table table-hover" v-if="purchaseOrder.bodies.length > 0">
              <thead>
                <tr>
                  <th>商品名稱</th>
                  <th>款式</th>
                  <th>數量</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr v-for="(data, index) in purchaseOrder.bodies" :key="data.id">
                  <td>{{ data.merchandise.name }}</td>
                  <td>{{ data.merchandiseStyle }}</td>
                  <td>{{ data.totalShortageQty }}</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createPurchaseOrder"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 至 Line Notify modal-->
  <PostToLineNotify
    :storeLineNotify="storeLineNotify"
    :propsSelectInfos="propsSelectInfos"
    :showLineNotifyModal="showLineNotifyModal"
    @notifyParentFromPostToLineNotify="notifyParentFromPostToLineNotify"
  ></PostToLineNotify>
  <!-- 至 Line 待推播列表 modal -->
  <ToLinePush
    :propsSelectInfos="propsSelectInfos"
    :lineBotData="lineBotData"
    :showLinePushModal="showLinePushModal"
    :defaultText="linePushD4Text"
    @notifyParentFromLinePush="notifyParentFromLinePush"
  ></ToLinePush>
  <!-- 匯出 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="excelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>是否將所選擇到的庫存匯出成 Excel 格式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/stock.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >上架區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 收單區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_DeadLineTimeRangeSwitch"
                v-model="advancedSearchData.deadLineTimeRange.switch"
              />
              <label class="form-check-label" for="AS_DeadLineTimeRangeSwitch"
                >收單區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.deadLineTimeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_DeadLineTimeRangeStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.deadLineTimeRange.startTime"
                  />
                  <label for="AS_DeadLineTimeRangeStartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_DeadLineTimeRangeEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.deadLineTimeRange.endTime"
                  />
                  <label for="AS_DeadLineTimeRangeEndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="AS_Name"
              maxlength="100"
              placeholder="商品名稱(有包含即可)(100字)"
              v-model="advancedSearchData.merchandiseName"
            />
            <label for="AS_Name">商品名稱(有包含即可)(100字)</label>
          </div>
          <!-- 供應商 -->
          <div class="form-floating mb-3" v-if="!suppliers.loading">
            <select
              class="form-select"
              id="AS_Supplier"
              v-model="advancedSearchData.supplier"
            >
              <option :value="null">不選擇</option>
              <option
                :value="data"
                v-for="data in suppliers.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
            <label for="AS_Supplier">供應商</label>
          </div>
          <AreaLoading v-else></AreaLoading>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 建立叫貨單_商品備註的 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="merchandiseOrderHasNoteModal"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body" style=" box-shadow: 0 0 20px gray">
          <!-- 上方標題 -->
          <div class="d-flex justify-content-between mb-1">
            <h5 class="modal-title fw-bolder">訂單備註 
              <a href="https://www.youtube.com/watch?v=OUQpuVpmKw0" target="_blank">
                <img
                  class="info-icon"
                  src="@/assets/icon/interrogation.png"
                />
              </a>
            </h5>
            <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ></button>
          </div>
          <!-- 內容 -->
          <div>
            <div
              style="background: #D4E2D4;"
              class="border rounded-3  p-2 m-0 mb-2"
              v-for="(merchandise, index) in merchandiseOrderHasNoteDatas"
              :key="index"
            >
              <!-- 商品資訊 -->
              <div class="row">
                <div class="col-3 p-0">
                  <Image
                    class="table-img"
                    :imageHash="merchandise[0].frontImage"
                    size="s"
                    v-if="merchandise[0].frontImage"
                    :alt="merchandise[0].frontImage"
                  ></Image>
                  <img
                    class="table-img"
                    v-else
                    src="@/assets/other-images/noImg.png"
                    alt=""
                  />
                </div>
                <div class="col-9 p-1 fw-bolder">
                  <p class="mb-2">商品名稱：</p>
                  <router-link 
                    :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${merchandise[0].merchandiseId}`"
                    class="text-primary"
                    target="_blank"
                  >
                    {{ merchandise[0].merchandiseName }}
                  </router-link>
                </div>
              </div>
              <hr class="mt-2" />
              <!-- 訂單備註 -->
              <div class="row px-1 py-2 border mb-2" style="background:#FAF3F0;" v-for="merchorder in merchandise">
                <div class="col-5 col-sm-3 d-flex">
                  <ul>
                    <!-- 顧客名稱 -->
                    <li class="fw-bolder">
                      顧客：
                      <router-link
                        :to="`/seller/store/${storeId}/participant/${merchorder.buyerId}/merchOrder`"
                        class="text-primary"
                        target="_blank"
                        >{{ merchorder.buyerName }}</router-link
                      >
                    </li>
                    <!-- 顧客 Line 名稱 -->
                    <li class="mt-1" v-if="merchorder.buyerLineName">
                      <img class="icon" title="顧客的 Line 名稱" src="@/assets/icon/line-icon.png" alt=""/>
                      <span>&ensp;{{ merchorder.buyerLineName }}</span>
                    </li>
                    <!-- 顧客 FB 名稱 -->
                    <li class="mt-1" v-if="merchorder.buyerFbName">
                      <img class="icon" src="@/assets/icon/facebook-2.jpg" title="顧客的 Facebook 名稱" alt="" />
                      <span>&ensp;{{ merchorder.buyerFbName }}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-7 col-sm-9 border-start">
                  <!-- 訂單編號 & 款式 -->
                  <div class="row mb-2">
                    <!-- 訂單編號 -->
                    <div class="col p-0" title="訂單編號">
                      <img class="icon" src="@/assets/icon/bookmark.png" alt="" />
                      ：
                      <router-link
                        class="fw-bolder"
                        :to="`/seller/store/${storeId}/merchOrder?search=${merchorder.merchOrderId}`"
                        target="_blank"
                      >
                        {{ merchorder.merchOrderId }}
                      </router-link>
                    </div>
                    <!-- 款式 -->
                    <div class="col">
                      <span class="fw-bolder">款式：</span> {{merchorder.merchandiseStyleName}}
                    </div>
                  </div>
                  <!-- 備註 -->
                  <div>
                    <span class="fw-bolder">備註：</span>
                    <span style="font-style: italic;">{{merchorder.merchOrderNote}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部 -->
          <div class="text-end">
            <button
              type="button"
              class="tw-btn tw-btn-secondary "
              data-bs-dismiss="modal"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'    //optional for column grouping
import Row from 'primevue/row'                    //optional for row
import { FilterMatchMode } from 'primevue/api'
// components
import ToLinePush from '../../../components/modal/ToLinePush.vue'
import PostToLineNotify from '../../../components/modal/PostToNotify.vue'
import ProgressBar from '../../../components/primeVue/ProgressBar.vue'
import FieldFilter from '../../../components/tools/FieldFilter.vue'
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  components: { DataTable, Column, ColumnGroup, Row,
    ToLinePush, PostToLineNotify, ProgressBar, FieldFilter
  },
  data() {
    return {
      // modal
      purchaseOrderModal: {},
      excelModal: {},
      introductionModal: {},
      advancedSearchModal: {},
      merchandiseOrderHasNoteModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      stockList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 進度條資料
      progressData: {
        target: 'stock',
        percent: 0
      },
      // 選擇到的物件
      selectItems: [],
      errorData: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      // 賣場資訊
      storeInfo: {},
      // 供應商
      suppliers: {
        loading: false,
        data: []
      },
      // 賣場綁定 line bot
      lineBotData: {
        loading: false,
        data: []
      },
      // 紀錄賣場是否有綁定 line notify和本身是否有綁定自己的
      storeLineNotify: {
        loading: false,
        bindStore: [],
        bindMyself: null
      },
      // 建立叫貨單
      merchandiseOrderHasNoteDatas: {}, // 存放訂單備註
      orderGoods: 0,
      total: 0,
      payable: 0,
      purchaseOrder: {
        header: {
          adjustment: 0,
          note: '',
          shippingCost: 0,
          storeId: '',
          supplierId: 0,
        },
        bodies:[]
      },
      // 至 Line 待推播列表：傳至元件的資料
      showLinePushModal: false,
      // 開啟發布至 Line Notify
      showLineNotifyModal: false,
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        deadLineTimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        merchandiseName: '',
        supplier: null,
        singleSelect: {
          arrivalStatus: '需叫貨',
          hasStock: '不選擇',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'purchaseOrderModal','excelModal','introductionModal', 'advancedSearchModal', 'merchandiseOrderHasNoteModal'
    ])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  beforeUnmount() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-stock-local')
    this.$methods.breakOff()
  },
  watch: {
    purchaseOrder: {
      handler(val, oldVal) {
        this.errorData = []
        this.orderGoods = 0
        this.total = 0
        this.payable = 0
        val.bodies.forEach((item) => {
          const totalShortageQty = item.totalShortageQty ? item.totalShortageQty : 0
          const cost = item.cost ? item.cost : 0
          console.log(item.cost)
          this.orderGoods += totalShortageQty
          this.total += totalShortageQty * cost
          if (!item.cost) this.errorData.push(item)
        })
        console.log(this.total + (!this.purchaseOrder.header.shippingCost ? 0 : this.purchaseOrder.header.shippingCost) + (!this.purchaseOrder.header.adjustment ? 0 : this.purchaseOrder.header.adjustment))
        this.payable = this.$methods.numberToFixed(this.total + (!this.purchaseOrder.header.shippingCost ? 0 : this.purchaseOrder.header.shippingCost) + (!this.purchaseOrder.header.adjustment ? 0 : this.purchaseOrder.header.adjustment))
        console.log(this.payable)
        this.total = this.$methods.numberToFixed(this.total)
      },
      deep: true,
    },
  },
  computed: {
    // 偏好 > 表格預設顯示筆數
    D4Row() {
      let D4Row = 20
      this.$store.state.Prefer.p_stock.rowCount.forEach(item => {
        if (item.selected) D4Row = item.value
      })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間
    timeRange() {
      return parseInt(this.$store.state.Prefer.p_stock.searchTimeRange[0].value)
    },
    // 偏好 > line 推播預設文字
    linePushD4Text() {
      return this.$store.state.Prefer.p_merchandise.linePushD4Text[0].value
    },
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_stock.fieldFilter
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-stock-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'arrivalStatus',
          key: '到貨狀態',
          value: [
            {
              id: 'A1',
              value: '不選擇'
            },
            {
              id: 'A2',
              value: '缺貨'
            },
            {
              id: 'A3',
              value: '需叫貨'
            },
          ]
        },
        {
          id: 'hasStock',
          key: '庫存狀態',
          value: [
            {
              id: 'B1',
              value: '不選擇'
            },
            {
              id: 'B2',
              value: '有庫存的商品'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
      this.scrollHeight = window.innerHeight - document.getElementById('StockTopArea').clientHeight - 160
    },
    // 開啟 modal
    showModal(status, item) {
      this.propsSelectInfos = {
        show: 'id',
        data: []
      }
      if (status === 'purchaseOrder') {
        // 建立叫貨單
        this.errorData = []
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆庫存')
        this.purchaseOrder = {
          header: {
            adjustment: 0,
            note: '',
            shippingCost: 0,
            storeId: 0,
            supplierId: this.selectItems[0].supplierId,
          },
          bodies:[]
        }
        this.purchaseOrder.bodies = JSON.parse(JSON.stringify(this.selectItems))
        const merchandiseIds = []
        this.orderGoods = 0
        this.total = 0
        this.payable = 0
        this.purchaseOrder.bodies.forEach(item => {
          if (!item.cost) this.errorData.push(item)
          if (!merchandiseIds.includes(item.merchandiseId)) merchandiseIds.push(item.merchandiseId)
          item.totalShortageQty = item.totalShortageQuantity
          this.orderGoods += item.totalShortageQty
          this.total += item.totalShortageQty * item.cost
        })
        this.payable = this.$methods.numberToFixed(this.total)
        this.total = this.$methods.numberToFixed(this.total)
        console.log(this.purchaseOrder)
        // 取得商品未完成訂單的資料 (為了渲染備註)
        this.getMerchandiseOrderData(merchandiseIds) 
        this.purchaseOrderModal.show()
      } else if (status === 'postToLineNotify') {
        // 發布至 line notify
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆庫存')
        if (this.storeLineNotify.loading) return this.SweetAlert('other', '請稍後~正在為您檢查是否綁定Line Notify')
        if (!this.storeLineNotify.loading && this.storeLineNotify.bindStore.length === 0 && !this.storeLineNotify.bindMyself) return this.SweetAlert('other', '您尚未綁定任何Line Notify')
         // 取不重複商品資料
        const set = new Set()
        const merchandiseNoRepeat = this.selectItems.filter(item => !set.has(item.merchandiseId) ? set.add(item.merchandiseId) : false)
        const merchandise = []
        merchandiseNoRepeat.forEach(item => merchandise.push(item.merchandise))         
        this.propsSelectInfos.show = 'name'
        console.log("merchandise:",merchandise)
        this.propsSelectInfos.data = merchandise
        this.showLineNotifyModal = true
      } else if (status === 'toLinePush') {
        // 至 Line 待推播列表
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆庫存')
          // 取不重複商品資料
          const set = new Set()
          const merchandiseNoRepeat = this.selectItems.filter(item => !set.has(item.merchandiseId) ? set.add(item.merchandiseId) : false)
          const merchandise = []
          merchandiseNoRepeat.forEach(item => merchandise.push(item.merchandise))       
          this.propsSelectInfos.show = 'name'
          this.propsSelectInfos.data = merchandise
          this.showLinePushModal = true
      } else if (status === 'exportExcel') {
        // 匯出 Excel
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆庫存')
          this.propsSelectInfos.data = this.selectItems
          this.excelModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      } else if (status === 'merchandiseOrderHasNote') {
        // 叫貨時，提供商品的訂單備註資料
        this.merchandiseOrderHasNoteModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getSuppliers,getStoreLineNotifyLinks,getOwner{getUser{getUserLineLink}},getStoreLineGroupLinks{getSummary}}'
        }
      ]
      this.storeInfo = {}
      this.suppliers = {
        loading: true,
        data: []
      }
      this.lineBotData = {
        loading: true,
        data: []
      }
      this.storeLineNotify = {
        loading: true,
        bindStore: [],
        bindMyself: null
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // 供應商
            vm.suppliers = {
              loading: false,
              data: storeInfo.suppliers
            }
            // 賣場綁定 line bot
            const lineBotInfo = storeInfo.storeLineGroupLinks
            lineBotInfo.forEach(item => {
              item.summary.linkId = item.id
              if (!item.deleted) vm.lineBotData.data.push(item.summary)
            })
            vm.lineBotData.loading = false
            // 賣場綁定 line Notify 與 自己是否綁定
            vm.storeLineNotify = {
              loading: false,
              bindStore: storeInfo.storeLineNotifyLinks,
              bindMyself: storeInfo.owner.user.userLineLink.notifyAccessToken ? storeInfo.owner : null
            }
            // 先取得預設時間區間
            vm.computedD4TimeRange()
            vm.advancedSearch()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 更新資料 (start)
    /**
     * 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let data = []
      let ids = []
      this.selectItems.forEach(stock => ids.push(stock.id))
      switch (updateItem) {
        case 'stock':
          data = [{
            ids: ids,
            type: 24,
            methods: '{getCheckedNotShippedQuantity,getAllocatedQuantity,getAvailableFromStockQuantity,getOutOfStockQuantity,getPurchasedQuantity,getTotalShortageQuantity,getPaidShortageQuantity}'
          }]
          break;
        default:
          throw new Error('Stock.vue > updateData > updateItem 未處理')
      }
      // 取要更新的資料
      const updateData = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      };
      const vm = this
      $.ajax({
        type: "POST",
        async: true,
        url: updateData,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const stocks = res.data[0].objects
            vm.sortoutUpdateData(stocks, updateItem, modal)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 整理更新資料
    sortoutUpdateData(stocks, updateItem, modal) {
      this.stockList.forEach(origin => {
        stocks.forEach(update => {
          if (origin.id === update.id) {
            switch (updateItem) {
              case 'stock':
                origin.checkedNotShippedQuantity = update.checkedNotShippedQuantity
                origin.quantity = update.quantity
                origin.allocatedQuantity = update.allocatedQuantity
                origin.availableFromStockQuantity = update.availableFromStockQuantity
                origin.outOfStockQuantity = update.outOfStockQuantity
                origin.purchasedQuantity = update.purchasedQuantity
                origin.totalShortageQuantity = update.totalShortageQuantity
                origin.paidShortageQuantity = update.paidShortageQuantity
                break;
              default:
                throw new Error('Stock.vue > sortoutUpdateData > updateItem 未處理')
            }
          }
        })
      })
      this.SweetAlert('200')
      if (modal) {
        this[modal].hide()
        this.selectItems = []
      }
      this.$methods.switchLoading('hide')
    },
    // * 更新資料 (end)
    // * 取資料 (start)
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.arrivalStatus = '需叫貨'
      this.advancedSearchData.singleSelect.hasStock = '不選擇'
      // 判斷是哪個單選項目要變更
      if (searchStatus === '缺貨' || searchStatus === '需叫貨') singleSelectOption = 'arrivalStatus'
      else if (searchStatus === '有庫存的商品') singleSelectOption = 'hasStock'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      // 先中止 call API 跟同步動作
      this.$methods.breakOff([this._abortSortOutStocks])
      // 執行進階搜尋步驟
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.selectItems = []
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      const query = {"columns":[{"columnName":"id","siteObjectType":24}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":16},"operator":{"operator":"=","type":1,"value":this.storeId}}],"operator":1},"joins":[{"leftColumn":{"columnName":"merchandiseId","siteObjectType":24},"rightColumn":{"columnName":"id","siteObjectType":16},"type":0}],"orders":[{"asc":false,"column":{"columnName":"id","siteObjectType":24}}],"siteObjectType":24,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'releaseTime',
              siteObjectType: 16
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'releaseTime',
              siteObjectType: 16
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 收單時間
      if (this.advancedSearchData.deadLineTimeRange.switch) {
        if (this.advancedSearchData.deadLineTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.deadLineTimeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'deadLineTime',
              siteObjectType: 16
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.deadLineTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.deadLineTimeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'deadLineTime',
              siteObjectType: 16
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 商品名稱
      if (this.advancedSearchData.merchandiseName) {
        query.group.groups.push({
          column: {
            columnName: "name",
            siteObjectType: 16,
          },
          operator: {
            operator: "like",
            type: 1,
            value: `%${this.advancedSearchData.merchandiseName}%`,
          },
        })
      }
      // 庫存狀態
      if (this.advancedSearchData.singleSelect.hasStock === '有庫存的商品') {
        query.group.groups.push({
          column: {
            columnName: "quantity",
            siteObjectType: 24,
          },
          operator: {
            operator: '>',
            type: 1,
            value: 0,
          },
        })
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getStockList(query)
    },
    // 取得庫存
    getStockList(query) {
      const vm = this
      const getStockListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getMerchOrders,getStockStyleLinks,getMerchandiseStyles,getCheckedNotShippedQuantity,getAllocatedQuantity,getAvailableFromStockQuantity,getOutOfStockQuantity,getPurchasedQuantity,getTotalShortageQuantity,getPaidShortageQuantity,getMerchandise{getMerchandisePictureLinks,getMerchandiseSupplierLinks{getSupplier},getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}}}}',
          pagination: {
            limit: 100
          }
        }
      ]
      this.stockList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getStockListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log("資料抓取成功")
            const stockList = res.data[0].objects
            const paging = res.data[0].paging
            vm.stockMap(stockList, paging)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理庫存列表
    stockMap(stockList, paging) {
      this._abortSortOutStocks = setTimeout(() => {
        stockList.forEach((item, index) => {
          console.log(item)
          // 原資料
          const object = {
            allocatedQuantity: item.allocatedQuantity,
            availableFromStockQuantity: item.availableFromStockQuantity,
            cost: (!this.currentParticipantPermission || this.currentParticipantPermission.accessCost) ? item.merchandiseStyles[0].cost : 0,
            checkedNotShippedQuantity: item.checkedNotShippedQuantity,
            id: item.id,
            limitedQuantity: item.limitedQuantity,
            merchOrders: item.merchOrders,
            merchOrderIds: [],
            merchandise: item.merchandise,
            merchandiseId: item.merchandiseId,
            merchandiseSupplierLinks: {},
            merchandisePictureLinks: {},
            merchandiseStyles: item.merchandiseStyles,
            merchandiseStyle: '',
            deadLineTime: item.merchandise.deadLineTime ? this.$methods.moment(item.merchandise.deadLineTime).format('YYYY-MM-DD HH:mm:ss') : '-',
            outOfStockQuantity: item.outOfStockQuantity,
            paidShortageQuantity: item.paidShortageQuantity,
            purchasedQuantity: item.purchasedQuantity,
            quantity: item.quantity,
            stockStyleLinks:item.stockStyleLinks,
            supplierName: '－',
            supplierId: null,
            totalShortageQuantity: item.totalShortageQuantity
          }
          // 需特別處理
          // 供應商
          let supplierArray = []
          object.merchandise.merchandiseSupplierLinks.forEach(supplierLink => {
            if (supplierLink.preset) {
              object.supplierId = supplierLink.supplierId
              object.supplierName = supplierLink.supplier.name
            }
            const supplierObject = {
              cost: supplierLink.cost,
              id: supplierLink.id,
              merchandiseId: supplierLink.merchandiseId,
              preset: supplierLink.preset,
              name: supplierLink.supplier.name,
              supplierId: supplierLink.supplierId
            }
            supplierArray.push(supplierObject)
          })
          object.merchandiseSupplierLinks = supplierArray
          // 訂單圖示
          object.merchandisePictureLinks = object.merchandise.merchandisePictureLinks
          // 訂單款式
          let merchandiseStyle = ''
          object.merchandiseStyles.forEach((item, index, arr) =>{
            merchandiseStyle += item.name + ' '
          })
          object.merchandiseStyle = merchandiseStyle
          // 訂單編號
          object.merchOrders.forEach(item => {
            object.merchOrderIds.push(item.id)
          })

          // 進階搜尋 > 供應商
          let matchSupplier = false
          if ((this.advancedSearchData.supplier && object.supplierId === this.advancedSearchData.supplier.id) || !this.advancedSearchData.supplier) matchSupplier = true
          else matchSupplier = false

          // 進階搜尋 > 到貨狀態
          let matchArrivalStatus = false
          switch(this.advancedSearchData.singleSelect.arrivalStatus) {
            case '缺貨':
              if (item.outOfStockQuantity > 0) matchArrivalStatus = true
              break;
            case '需叫貨':
              if (item.totalShortageQuantity > 0) matchArrivalStatus = true
              break;
            case '不選擇':
              matchArrivalStatus = true
              break;
          }

          if (matchSupplier && matchArrivalStatus) this.stockList.push(JSON.parse(JSON.stringify(object)))
        })
        console.log(this.stockList)
        // 處理分頁
        if (paging.current !== paging.total) {
          this.$api.getNextSiteObjects(paging.next).then(res => {
            const result = res.data[0].objects
            const paging = res.data[0].paging
            this.stockMap(result, paging)
          }).catch(err => {
            console.log(err)
            this.SweetAlert('other', err.responseJSON.message)
          })
        }
        this.progressData.percent = this.$methods.handleProgressBar(paging.current, paging.total)
        this.dataTabelLoading = false
      }, 1)
      this.$methods.switchLoading('hide')
    },
    // * 取資料 (end)
    // 建立叫貨單模組_取得商品未完成訂單的資料 (為了渲染備註)
    getMerchandiseOrderData(merchandiseIds) {
      this.$methods.switchLoading('show')
      const vm = this
      let searchData = {
        storeId: this.storeId,
        orderStatus: "unfinished",
        merchandiseIds: merchandiseIds,
      }
      this.$api.merchOrder.getDataTable(searchData).then(res => {
        console.log(res);
        if (res.code === '200') {
          const merchorders = res.data
          console.log(merchorders)
          vm.merchandiseOrderHasNoteDatas={}
          // 依照商品分類
          merchorders.forEach((item) => {
            if(item.merchOrderNote) {
              if(vm.merchandiseOrderHasNoteDatas[item.merchandiseId]) {
                vm.merchandiseOrderHasNoteDatas[item.merchandiseId].push(item)
              } else {
                vm.merchandiseOrderHasNoteDatas[item.merchandiseId] = [item]
              }
            }
          })
          this.$methods.switchLoading('hide')
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 建立叫貨單
    createPurchaseOrder() {
      const vm = this
      this.$methods.switchLoading('show')
      const createPurchaseOrderApi = `${process.env.VUE_APP_API}/purchaseOrder/create`
      const token = this.serverToken
      const header = {
        authorization: token,
      }
      if (this.purchaseOrder.header.supplierId === null) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請選擇供應商')
      }
      const orderHeader = {
        storeId: this.storeId,
        supplierId: this.purchaseOrder.header.supplierId,
        shippingCost: this.purchaseOrder.header.shippingCost === '' ? 0 : this.purchaseOrder.header.shippingCost,
        adjustment: this.purchaseOrder.header.adjustment === '' ? 0 : this.purchaseOrder.header.adjustment,
        note: this.purchaseOrder.header.note
      }
      let bodies = []
      this.purchaseOrder.bodies.forEach(item => {
        let merchandiseStyleIds = []
        item.merchandiseStyles.forEach(merchandiseStyle => {
          merchandiseStyleIds.push(merchandiseStyle.id)
        })
        let obj = {
          cost: item.cost,
          quantity: Math.trunc(item.totalShortageQty),
          merchOrderId: item.id,
          merchandiseStyleIds : merchandiseStyleIds
        }
        bodies.push(obj)
      })
      const data = [{
        header: orderHeader,
        bodies: bodies,
      }]
      console.log(data)
      $.ajax({
        type: 'POST',
        async: true,
        url: createPurchaseOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.updateData('stock', 'purchaseOrderModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 來自 <發布至 line notify> 子層的通知
    notifyParentFromPostToLineNotify(object) {
      if (object.clearSelectItems) this.selectItems = []
      this.showLineNotifyModal = object.showLineNotifyModal
    },
    // 來自 <至 Line 待推播列表> 子層通知
    notifyParentFromLinePush(object) {
      if (object.clearSelectItems) this.selectItems = []
      this.showLinePushModal = object.showLinePushModal
    },
    // 匯出 Excel
    exportExcel() {
      const token = this.serverToken
      let exportExcelArr = []
      this.selectItems.forEach((item) => {
        exportExcelArr.push(item.id)
      })
      const ids = exportExcelArr.toString()
      const exportExportUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${token}&siteObjectTypeIndex=24&ids=${ids}`
      window.open(exportExportUrl, "_blank")
      this.selectItems = []
      this.excelModal.hide()
    },
  }
  
}
</script>